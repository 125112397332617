import React from "react";
import styled, { keyframes } from "styled-components";
import Marquee from "./atoms/Marquee";

const Experience = () => {
  return (
    <Section>
      <Marquee text="CALL NOW" speed="55s" />
      <Content>
        <Header>
          <Status>𑖌𑖼</Status>
          <Availability>
            ZEE IS
            <br />
            AVAILABLE
          </Availability>
        </Header>
        <ExperienceGrid>
          <h4 style={{ textAlign: "center", margin: "0 0 1.11rem 0 " }}>
            DESIGN XP
          </h4>
          <Row>
            <Exp>Web3 Venture Studios</Exp>
            <Time>2yr</Time>
          </Row>
          <Row>
            <Exp>Inclusive Design Team Lead</Exp>
            <Time>1yr</Time>
          </Row>
          <Row>
            <Exp>Consulting</Exp>
            <Time>3yr</Time>
          </Row>
          <Row>
            <Exp>Agency</Exp>
            <Time>1yr</Time>
          </Row>
          <Row>
            <Exp>Founder</Exp>
            <Time>1yr</Time>
          </Row>
          <ResumeLink target="_blank" href="https://read.cv/z33">
            View Resume
          </ResumeLink>
        </ExperienceGrid>
      </Content>
      <Marquee text="CALL NOW" />
    </Section>
  );
};

export default Experience;

const Section = styled.section`
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  margin: 7.77rem auto;
  display: flex;
  flex-direction: column;
`;
const Content = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.11rem;
  text-align: center;
  @media (max-width: 800px) {
  }
`;
const Availability = styled.h3`
  font-family: "AxExtended";
`;
const flash = keyframes`0%{opacity:1;}100%{opacity:0;}`;
const Status = styled.div`
  width: 11px;
  height: 11px;
  flex-shrink: 1;
  border-radius: 1000px;
  // margin-left: 12px;
  // margin-top: -6px;
  // background-color: ${({ theme }) => theme.text};
  animation: ${flash} 2s ease-in-out infinite;
`;

const ExperienceGrid = styled.div`
  width: 320px;
  margin-bottom: 2.22rem;
  display: flex;
  flex-direction: column;
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;
const Exp = styled.h4`
  margin: 0;
`;

const ResumeLink = styled.a`
  border: 1px solid ${({ theme }) => theme.grids};
  color: ${({ theme }) => theme.text};
  text-align: center;

  padding: 0.99rem 0 1.22rem 0;
  margin-top: 3.33rem;
  text-decoration: underline;
  border-radius: 1rem 0.5rem 1rem 0.5rem;

  &:hover {
    background-color: ${({ theme }) => theme.accent};
  }
`;
const Time = styled.h4`
  flex-shrink: 1;
  margin: 0;
`;
