import React from 'react';
import GlobalLayout from '../layouts/GlobalLayout';
import { MainHero, Nav, WorkLinks } from '../components';
import { Who } from '../components/who';
import { Faqs } from '../components';
import Experience from '../components/Experience';

const IndexPage = () => {
  // const [modal, toggleModal] = useToggle(false);
  // const randy = Math.floor(Math.random() * 88888) + 11111;
  // console.log(randy);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     toggleModal(true);
  //   }, randy);
  //   // I will be deleted while component is unmounting.
  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <GlobalLayout>
        <Nav />
        <MainHero />
        <Who />
        <Experience />
        <WorkLinks />
        <Faqs />
      </GlobalLayout>
    </>
  );
};

export default IndexPage;
