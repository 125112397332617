import React from "react";
import styled from "styled-components";
import EyeStatic from "../images/EyeStatic.gif";

export const Who = () => {
  return (
    <Container>
      <GradBlur />
      <EyeStaticBG />
      <Content>
        <Title>Crypto Native Creative</Title>
        <br />
        <Subtitle>Product Design // Brand Design</Subtitle>
      </Content>
    </Container>
  );
};
const Container = styled.div`
  // max-width: 100vw;
  // width: 100vw;
  height: 88vmax;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const GradBlur = styled.div`
  width: 99vmin;
  height: 99vmin;
  position: absolute;
  z-index: 1;
  border-radius: 1000px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    ${({ theme }) => theme.background}00 0%,
    ${({ theme }) => theme.background} 66%,
    ${({ theme }) => theme.background} 100%
  );
  // background: radial-gradient(
  //       50% 50% at 50% 50%,
  //       #07070744 0%,
  //       #07070700) 25%,
  //       #07070744) 50%,
  //       #07070700) 75%,
  //       #070707 100%
  //     )
  //     ;
  // );
`;

const EyeStaticBG = styled.div`
  width: 88vmin;
  height: 88vmin;
  position: absolute;
  border-radius: 1000px;

  background-image: url(${EyeStatic});
  background-size: cover;
  background-repeat: none;

  opacity: 44%;
  z-index: -2;
`;

// typography

const Title = styled.h3`
  margin-bottom: -0.88em;
  text-align: center;
`;
const Subtitle = styled.h5``;

const Content = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
